<template>
  <el-drawer title="限时详情" :visible.sync="isDrawer" :with-header="false" size="86%" :before-close="handleClose"
    :modal-append-to-body="false">
    <div class="h-full flex flex-dc" v-if="isDrawer">
      <div class="drawer_title pall-30 flex flex-jb flex-n">
        <div class="fs-18 fw-bold">限时商品详情</div>
        <i class="el-icon-close fs-20 pointer" @click="handleClose"></i>
      </div>
      <div class="flex-1 overflow-scroll-y">
        <t-simple-form :ref-obj.sync="formOpts.ref" :formOpts="formOpts" :widthSize="2" @handleEvent="handleEvent"
          class="drawer_form">
          <!-- 平台自定义插槽 -->
          <template #strJson>
            <div class="pos-s t-0 bgc-fff zi-10">
              <div class="pb-20 pl-40 flex flex-ac flex-jb">
                <div class="fs-14 fw-bold flex-1">
                  <span class="h-10 w-5 bgc-38393b mr-10 dis-in-block"></span>
                  <span>折扣商品</span>
                </div>
                <div class="flex flex-ac">
                  <div class="mr-30">数量：{{formOpts.formData.strJson.length}}</div>
                  <el-button type="primary" @click="addDomain">新增</el-button>
                </div>
              </div>
            </div>
            <div v-for="(domains,index) in formOpts.formData.strJson" class="flex flex-ac mb-20" :key="index">
              <el-form-item label="系列分类" label-position="right" :prop="'strJson.' + index + '.boxType'"
                :rules="{required: true, message: '请至少选择一个系列类型', trigger: 'change'}" class="flex flex-ac">
                <el-select v-model="domains.boxType" clearable placeholder="请选择" @change="handBoxTypeChange(domains)">
                  <el-option v-for="item in boxTypes" :key="item.value" :label="item.key" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="系列名称" label-position="right" :prop="'strJson.' + index + '.seriesId'"
                :rules="{required: true, message: '请至少选择一个系列', trigger: 'change'}" class="flex flex-ac">
                <el-select v-model="domains.seriesId" clearable filterable :loading="sleLoading" placeholder="请选择"
                  @change="handSeriesChange($event,domains)">
                  <el-option v-for="item in formatList(domains.boxType)" :key="item.id" :label="item.seriesName"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="折扣价格" label-position="right" :prop="'strJson.' + index + '.soldPrice'"
                :rules="{required: true, message: '商品价格不能为空', trigger: 'blur'}" class="flex flex-ac">
                <el-input v-model="domains.soldPrice"></el-input>
              </el-form-item>
              <el-button type="primary" plain @click.prevent="removeDomain(index)" class="ml-20"
                v-if="formOpts.formData.strJson.length > 1">删除
              </el-button>
            </div>
          </template>
        </t-simple-form>
      </div>
      <div class="drawer_button pall-30 flex-n flex flex-ac flex-je">
        <el-button type="primary" @click="submitForm" :loading="butLoading">{{goodId?'保存':'提交'}}</el-button>
        <el-button type="primary" plain @click="handleClose">取消</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'limitActivitiesDetails',
  components: {
    "TSimpleForm": (resolve) => require(["@/components/public/TSimpleForm"], resolve),
  },
  computed: {
    ...mapState('menu', {
      'boxTypes': state => state.dictList ? state.dictList.box_type : [],  //商品类型
    })
  },
  data () {
    return {
      goodId: '',//详情id
      isDrawer: false,
      sleLoading: false,
      butLoading: false,
      formOpts: {
        labelPosition: 'top',
        ref: null,
        formData: {
          startDate: '',
          endDate: '',
          limitDate: '',
          timeId: '',
          strJson: [{
            boxType: '',
            seriesId: '',
            soldPrice: '',
          }], //商品信息
        },
        fieldList: [
          { label: '限时日期', value: 'limitDate', type: 'datetimerange', comp: 'el-date-picker', event: 'showLimitDate', bind: { format: "yyyy-MM-dd HH:mm:ss", valueFormat: "yyyy-MM-dd HH:mm:ss", rangeSeparator: "至", startPlaceholder: "开始日期", endPlaceholder: "结束日期" } },
          { label: '', slotName: 'strJson', className: 't-form-block' },
        ],
        rules: {
          limitDate: [{ required: true, message: '请输入开始时间和结束时间', trigger: 'blur' }],
        },
        //按钮
        operatorList: [],
        // 相关列表
        listTypeInfo: {}
      },
      drawsSeriesList: [],
      rewardSeriesList: [],
      goodsSeriesList: [],
    }
  },
  mounted () { },
  methods: {
    // 初始化
    init (id) {
      this.isDrawer = true
      this.goodId = id
      if (id) {
        this.getDetails(id)
      }
      console.log('格式化数据', this.formOpts.formData)
      this.findBoxSeries()
      this.finGoodSeriesAll()
      this.findGoodsMallList()
    },
    //关闭弹框
    handleClose () {
      this.isDrawer = false
      this.resetForm()
    },
    //获取详情数据
    getDetails (id) {
      this.$http.get(`/timActy/findTimActyListBytimeId?timeId=${id}`).then(({ data: res }) => {
        let goodData = res.data
        goodData.limitDate = [goodData.startDate, goodData.endDate]
        this.formOpts.formData = goodData
      })
    },
    // 触发事件
    handleEvent (type, val) {
      switch (type) {
        case 'showLimitDate':
          console.log(val, type)
          if (val && val.length > 0) {
            this.formOpts.formData.startDate = val[0]
            this.formOpts.formData.endDate = val[1]
          } else {
            this.formOpts.formData.startDate = ''
            this.formOpts.formData.endDate = ''
          }
          break
      }
    },
    //格式化选中列表
    formatList (key) {
      if (key == 3) {
        return this.drawsSeriesList
      } else if (key == 5) {
        return this.rewardSeriesList
      } else if (key == 7) {
        return this.goodsSeriesList
      } else {
        return []
      }
    },
    //查询所有抽盒
    findBoxSeries () {
      this.$http.get('/boxSeries/findBoxSeries').then(({ data: result }) => {
        this.drawsSeriesList = result.data
      }).catch(err => { })
    },
    //查询所有一番赏
    finGoodSeriesAll () {
      this.$http.get('/goodSeries/finGoodSeriesAll').then(({ data: result }) => {
        this.rewardSeriesList = result.data
      }).catch(err => { })
    },
    //查询精品盲盒
    findGoodsMallList () {
      this.$http.get('/goodsMall/findGoodsMallList').then(({ data: result }) => {
        this.goodsSeriesList = result.data
      }).catch(err => { })
    },
    //系列分类选择
    handBoxTypeChange (domains) {
      domains.seriesId = ''
      domains.soldPrice = ''
    },
    //系列选中值发生变化
    handSeriesChange (value, domains) {
      console.log(value, domains, '---------')
      let seriesList = []
      if (domains.boxType == 3) {
        seriesList = this.drawsSeriesList
      } else if (domains.boxType == 5) {
        seriesList = this.rewardSeriesList
      } else if (domains.boxType == 7) {
        seriesList = this.goodsSeriesList
      }
      let index = seriesList.findIndex(ele => ele.id == value)
      domains.soldPrice = seriesList[index].soldPrice
    },
    // 新增商品
    addDomain () {
      this.formOpts.formData.strJson.push({
        boxType: '',
        seriesId: '',
        soldPrice: '',
      })
    },
    removeDomain (index) {
      this.formOpts.formData.strJson.splice(index, 1)
    },
    // 提交form表单
    submitForm () {
      this.formOpts.ref.validate((valid) => {
        if (valid) {
          this.butLoading = true
          if (!valid) return
          console.log('最终数据', this.formOpts.formData)
          let goodData = JSON.parse(JSON.stringify(this.formOpts.formData))
          goodData.strJson = JSON.stringify(goodData.strJson)
          delete goodData.limitDate
          let apiUrl = ''
          if (goodData.timeId) {//修改
            apiUrl = '/timActy/editTimActy'
          } else {//新增
            apiUrl = '/timActy/saveTimActy'
          }
          this.$http.post(apiUrl, goodData).then(res => {
            this.$message({
              message: '新增成功',
              type: 'success'
            })
            this.butLoading = false
            this.$emit('refresh')
            this.handleClose()
          }).catch(err => {
            this.butLoading = false
          })
        }
      })
    },
    // 重置form表单
    resetForm () {
      Object.assign(
        this.$data.formOpts.formData,
        this.$options.data.call(this).formOpts.formData
      )
      this.$nextTick(() => {
        this.formOpts.ref.clearValidate()
      })
    },
  }
}
</script>

<style lang="less" scoped>
</style>